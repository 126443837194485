import styled, { css } from 'styled-components';

import { Button } from '~/components/Buttons';
import RickTextView from '~/components/RickTextView';

import { COLORS } from '~/styles';

export const AnswerWrapper = styled.div`
  position: relative;
  margin: 10px 0 24px;
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
  padding: 11px 28px 11px;
  background: ${COLORS.BG_LIST};

  &:hover {
    background: ${COLORS.HOVER};

    & > button {
      pointer-events: auto;
      opacity: 1;
    }
  }
`;

export const EditButton = styled(Button)`
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 8px;
  right: 20px;
`;

export const Answer = styled(RickTextView)`
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
`;

export const AnswerPlaceholder = styled.div`
  font-size: 14px;
  color: ${COLORS.PLACEHOLDERS};

  &:hover {
    background: ${COLORS.HOVER};

    & > button {
      pointer-events: auto;
      opacity: 1;
    }
  }
`;

export const Row = styled.div`
  margin-bottom: 3px;
`;

export const AnswerContainer = styled.div<{
  $isZeroState?: boolean;
  clickable?: boolean;
}>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: 48px;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  background-color: ${COLORS.BG_LIST};

  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  &:hover {
    background: ${COLORS.HOVER};

    ${({ $isZeroState }) =>
      !$isZeroState
        ? css`
            span {
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.16px;
              color: ${COLORS.TEXT_MAIN};
            }
          `
        : null}
  }
`;

export const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  color: ${COLORS.TEXT_MAIN};
  font-size: 14px;
  > span {
    font-size: 14px;
    color: ${COLORS.SUBTEXT};
    align-self: center;
  }
  & svg {
    margin-right: 21px;
  }
  }
`;

export const Comment = styled(RickTextView)`
  display: flex;
  flex-direction: row;
`;

export const IconWrapper = styled.div<{ $answered: boolean }>`
  display: flex;
  margin-right: 10px;

  ${({ $answered }) =>
    !$answered &&
    css`
      color: ${COLORS.INACTIVE};
    `}
`;
