import styled, { css } from 'styled-components';

import { Button } from '~/components/Buttons';

import { COLORS } from '~/styles';

export const AnswerWrapper = styled.div`
  position: relative;
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
  padding: 4px 0 11px;
`;

export const ExpandedWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    & > div {
      background: ${COLORS.HOVER};
    }
    & > button {
      pointer-events: auto;
      opacity: 1;
    }
  }
`;

export const Arrow = styled.div<{ background: string; marginRight: number }>`
  width: 0;
  height: 0;
  margin-left: auto;
  margin-right: ${({ marginRight }) => marginRight}px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 8px solid ${({ background }) => background};
`;

export const Answer = styled.div<{ background: string }>`
  font-size: 14px;
  background: ${({ background }) => background};
  color: ${COLORS.TEXT_MAIN};
  height: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 25px 0 20px 34px;
  justify-items: center;
  margin-bottom: 24px;
`;

export const Label = styled.span`
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};

  & > span {
    color: ${COLORS.GRAY_LIGHT};
  }
`;

export const Comment = styled.div`
  font-size: 14px;
  width: 100%;
  color: ${COLORS.TEXT_MAIN};

  p {
    color: ${COLORS.TEXT_MAIN};
  }
`;

export const AnswerPlaceholder = styled.div`
  font-size: 14px;
  height: 54px;
  margin-bottom: 24px;
  color: ${COLORS.PLACEHOLDERS};
  background: ${COLORS.BG_LIST};
  display: flex;
  padding: 10px 0 10px 34px;
  align-items: left;
  gap: 10px;
  flex-direction: column;

  &:hover {
    background: ${COLORS.HOVER};

    & > button {
      pointer-events: auto;
      opacity: 1;
    }
  }
`;

export const Row = styled.div``;

export const AnswerContainer = styled.div<{ $isZeroState?: boolean; clickable?: boolean }>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: 48px;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  background-color: ${COLORS.BG_LIST};

  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  &:hover {
    background: ${COLORS.HOVER};

    ${({ $isZeroState }) =>
      !$isZeroState
        ? css`
            span {
              font-size: 14px;
              line-height: 1.86;
              letter-spacing: -0.16px;
              color: ${COLORS.TEXT_MAIN};
            }
          `
        : null}
  }
`;
export const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  color: ${COLORS.TEXT_MAIN};
  font-size: 14px;
  > span {
    font-size: 14px;
    color: ${COLORS.SUBTEXT};
    align-self: center;
  }
  & svg {
    margin-right: 21px;
  }
`;

export const RatingWrapper = styled.div<{ $answered: boolean; maxWidth: number }>`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 5px;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth}px;
`;

export const Rating = styled.div<{
  $answered?: boolean;
  selected: boolean;
  dark: string;
  default: string;
}>`
  flex: 1;
  border-radius: 3px;
  background-color: ${(props) => (props.selected ? props.default : COLORS.INACTIVE)};
  height: 20px;
  flex-basis: 20px;
  max-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    background-color: ${({ dark }) => dark};
  }
`;

export const Oval = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 50%;
`;

export const LabelWrapper = styled.div<{ $answered: boolean }>`
  display: flex;
  margin-right: 10px;
`;

export const EditButton = styled(Button)<{ top: number }>`
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: ${({ top }) => top}px;
  right: 20px;
`;
